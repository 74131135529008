import { extractApiErrorMessages } from "@teslagov/clarakm-js-react";
import { string } from "prop-types";
import { push } from "react-router-redux";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { captchaInfoToastr, claraToastr } from "../app/constants";
import { resetPasswordExpired } from "../login/duck/actions";
import { resetPassword, sendPasswordResetEmail } from "./PasswordResetAPI";
import {
  RESET_PASSWORD_WITH_CODE_REQUEST,
  SEND_RESET_PASSWORD_CODE_VIA_EMAIL_REQUEST,
  captchaV3Failure,
  resetPasswordWithCodeSuccess,
  sendResetPasswordCodeViaEmailFailure,
  sendResetPasswordCodeViaEmailSuccess,
  showModal
} from "./duck";
import { emailAddressSelector, hasFailedV3CaptchaSelector } from "./selectors";

export function* sendResetPasswordCodeViaEmail(action) {
  const emailAddress = yield select(emailAddressSelector);
  const isV2Captcha = yield select(hasFailedV3CaptchaSelector);
  const { token: captchaToken } = action;
  const body = { emailAddress, captchaToken, isV2Captcha };
  const { error } = yield call(sendPasswordResetEmail, body);

  if (error) {
    if (error?.response?.status === 403) {
      yield call(captchaInfoToastr);
      yield put(captchaV3Failure());
    }
    else {
      yield call(claraToastr.error, "Failed to send password reset email!", "");
      yield put(sendResetPasswordCodeViaEmailFailure());
    }
  }
  else {
    yield put(sendResetPasswordCodeViaEmailSuccess());
    yield put(showModal());
    yield put(push("/password-confirm"));
  }
}

export function* resetPasswordWithCode(action) {
  const { error } = yield call(resetPassword, action.payload);

  if (error) {
    const errorTitle = "Failed to Reset Password";
    const errorMessage = error instanceof string ?
      error :
      extractApiErrorMessages(error, false).pop() ??
        "Please ensure that the information you've entered is correct.";

    if (error.response?.data?.errors[0]?.code === 1005) {
      yield call(claraToastr.error, errorTitle, errorMessage);
    }
    else if (error.response.status === 403) {
      yield call(captchaInfoToastr);
      yield put(captchaV3Failure());
    }
    else {
      yield call(claraToastr.error, errorTitle, errorMessage);
    }
  }
  else {
    yield call(claraToastr.success, "Password has been successfully reset.", "");
    yield put(resetPasswordWithCodeSuccess());
    yield put(resetPasswordExpired());
    yield put(push("/login"));
  }
}

export const sagas = [ takeEvery(RESET_PASSWORD_WITH_CODE_REQUEST, resetPasswordWithCode), takeEvery(SEND_RESET_PASSWORD_CODE_VIA_EMAIL_REQUEST, sendResetPasswordCodeViaEmail) ];
